import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  RightCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { adminRoutes } from '../../configs/menuItems';

const { Sider } = Layout;

// Transform adminRoutes to antd menu format
const transformMenuItems = (routes) => {
  return routes.map(route => ({
    key: route.key,
    icon: route.icon,
    label: route.label,
    children: route.children.map(child => ({
      key: child.key,
      label: <Link to={child.path}>{child.label}</Link>
    }))
  }));
};

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const selectedKeysHandler = () => {
    const currentPath = location.pathname.split('/').pop();
    return [currentPath];
  };

  return (
    <Sider
      collapsible
      className="app-aside"
      collapsed={collapsed}
      collapsedWidth={60}
      onCollapse={(value) => setCollapsed(value)}
      trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
    >
      <Menu
        mode="inline"
        className="app-aside-menu"
        inlineIndent={16}
        selectedKeys={selectedKeysHandler()}
        items={transformMenuItems(adminRoutes)}
      />
    </Sider>
  );
};

export default Sidebar;
