import axios from '../utils/axiosConfig';

const API_BASE_URL = `${process.env.REACT_APP_APIGATEWAY_URL}${!process.env.REACT_APP_IS_DEV ? '/aidp' : ''}`;
export const getTableInfo = async () => {
  try {
    let url = `${API_BASE_URL}/aq/table_info`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error getting table info:`, error);
    throw error;
  }
};


export default getTableInfo;