import { BarChartOutlined, FileTextOutlined } from '@ant-design/icons';
import getTableInfo from '../api/grist';

// 新增一個快取物件
let tableInfoCache = null;

export const adminRoutes = [
  {
    label: '養殖管理報表',
    key: 'reports',
    icon: <BarChartOutlined />,
    children: [
      { label: '案場儀表板', key: 'site-overview', path: '/admin/site-overview', isGrafana: true },
      { label: '表單填寫統計', key: 'site-statistics', path: '/admin/site-statistics', isGrafana: true }
    ]
  },
  {
    label: '養殖專案管理',
    key: 'project',
    icon: <FileTextOutlined />,
    children: [
      { label: '案場列表', key: '養殖案場列表', path: '/admin/養殖案場列表' },
      { label: '魚池列表', key: '養殖魚池列表', path: '/admin/養殖魚池列表' },
      { label: '物種類別', key: '養殖物種類別', path: '/admin/養殖物種類別' },
      { label: '計劃任務', key: '養殖計劃任務', path: '/admin/養殖計劃任務' },
      { label: '計劃列表', key: '養殖計劃列表', path: '/admin/養殖計劃列表' },
      { label: '工班管理', key: '養殖工班管理', path: '/admin/養殖工班管理' },
      { label: '計劃任務生成設定', key: '養殖_計劃任務生成設定', path: '/admin/養殖_計劃任務生成設定' }
    ]
  },
  {
    label: '操作記錄細項',
    key: 'operations',
    icon: <FileTextOutlined />,
    children: [
      { label: '養殖_任務類別', key: '養殖_任務類別', path: '/admin/養殖_任務類別' },
      { label: '養殖表單_水質檢測', key: '養殖表單_水質檢測', path: '/admin/養殖表單_水質檢測' },
      { label: '養殖表單_試苗入苗', key: '養殖表單_試苗入苗', path: '/admin/養殖表單_試苗入苗' },
      { label: '養殖表單_消毒作業', key: '養殖表單_消毒作業', path: '/admin/養殖表單_消毒作業' },
      { label: '養殖表單_生長紀錄', key: '養殖表單_生長紀錄', path: '/admin/養殖表單_生長紀錄' },
      { label: '養殖表單_投餵紀錄', key: '養殖表單_投餵紀錄', path: '/admin/養殖表單_投餵紀錄' }
    ]
  }
];

// 修改 getGristViewId 函數
export const getGristViewId = async (path) => {
  // 如果快取不存在，才呼叫 API
  if (!tableInfoCache) {
    try {
      const table_info = await getTableInfo();
      tableInfoCache = table_info.data;
    } catch (error) {
      console.error('Failed to fetch table info:', error);
      return null;
    }
  }

  const key = decodeURIComponent(path.split('/').pop());
  // 從快取中找到對應的 table 資訊
  const tableInfo = tableInfoCache.find(table => table.id === key);
  
  // 如果找到對應的 table，回傳其 primaryViewId
  return tableInfo ? tableInfo.fields.primaryViewId : null;
};

export const getAllAdminPaths = () => {
  const paths = ['/admin'];
  adminRoutes.forEach(group => {
    group.children.forEach(item => {
      paths.push(item.path);
    });
  });
  return paths;
};

export const clearTableInfoCache = () => {
  tableInfoCache = null;
}; 