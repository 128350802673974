import React, { useState } from 'react';
import {
  GetEncryptedString,
  GetDecryptedString,
  GetEncryptObject,
  GetDecryptedObject,
} from '../utils/encryption';
import { sessionClear } from '../utils/functionTool';

//Auth 共享文本
const AuthContext = React.createContext();

export const baseAuthUrl = `${process.env.REACT_APP_APIGATEWAY_URL}${!process.env.REACT_APP_IS_DEV ? '/aidp' : ''}/google`

//
const GetInitUser = () => {
  const user = GetDecryptedObject(window.localStorage.getItem('user'));
  if (user) {
    return user;
  } else {
    return null;
  }
};

const GetInitToken = () => {
  const token = GetDecryptedString(window.localStorage.getItem('token'));
  if (token) {
    return token;
  } else {
    return null;
  }
};

const GetInitUserRole = () => {
  const userRole = GetDecryptedString(window.localStorage.getItem('userRole'));
  if (userRole) {
    return userRole;
  } else {
    return null;
  }
};

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(GetInitUser());
  const [token, setToken] = useState(GetInitToken());
  const [userRole, setUserRole] = useState(GetInitUserRole());

  const AuthUser = (authUser) => {
    if (authUser) {
      const token = `Bearer ${authUser.token.access_token}`;
      const role = authUser.user.user_role || 'user';
      
      window.localStorage.setItem('user', GetEncryptObject(authUser.user));
      window.localStorage.setItem('token', GetEncryptedString(token));
      window.localStorage.setItem('userRole', GetEncryptedString(role));
      
      setUser(authUser.user);
      setToken(token);
      setUserRole(role);
    }
  };

  const UnAuthUser = () => {
    sessionClear();
    setUser(null);
    setToken(null);
    setUserRole(null);
  };

  return (
    <AuthContext.Provider value={{ user, token, userRole, AuthUser, UnAuthUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
