import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Badge, Layout, Dropdown, Menu } from 'antd';
import { SettingOutlined, PoweroffOutlined, UserOutlined, FormOutlined, ControlOutlined } from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import SettingContext from '../../contexts/settingProvider';
import logo from '../../images/logo_s.svg';
import logo_b from '../../images/logo_b.svg';

const { Header } = Layout;

const AppHeader = () => {
  const { dark } = useContext(SettingContext);
  const { user, UnAuthUser, userRole } = useContext(AuthContext);
  const location = useLocation();

  const userItems = useMemo(
    () => [
      {
        key: 'userInfo',
        label: <Link to="/userInfo">使用者資訊</Link>,
        icon: (
          <Link to="/userInfo">
            <UserOutlined />
          </Link>
        ),
        name: '使用者資訊',
        path: '/userInfo',
      },
      {
        key: 'environment',
        label: <Link to="/settings">環境設定</Link>,
        icon: (
          <Link to="/settings">
            <SettingOutlined />
          </Link>
        ),
        name: '環境設定',
        path: '/settings',
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: <span onClick={() => UnAuthUser()}>登出</span>,
        icon: (
          <span onClick={() => UnAuthUser()}>
            <PoweroffOutlined />
          </span>
        ),
        name: '登出',
        path: '',
      },
    ],
    [UnAuthUser]
  );

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 'form',
        label: <Link to="/">前端表單填寫</Link>,
        icon: <FormOutlined />,
      }
    ];

    if (userRole === 'admin') {
      items.push({
        key: 'admin',
        label: <Link to="/admin">後台管理</Link>,
        icon: <ControlOutlined />,
      });
    }

    return items;
  }, [userRole]);

  const selectedKey = useMemo(() => {
    if (location.pathname === '/') return 'form';
    if (location.pathname === '/admin') return 'admin';
    return '';
  }, [location.pathname]);

  return (
    <Header className="app-header">
      <div className="app-header-container">
        <div className="app-header-left">
          <div className="app-header-logo">
            <Link to="/">
              <img src={dark ? logo : logo_b} alt="微電能源" />
            </Link>
          </div>
          <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            items={menuItems}
            className="app-header-menu"
          />
        </div>

        <ul className="app-header-right">
          <li>
            <div className="user">
              <Dropdown placement="bottom" menu={{ items: userItems }} trigger={['click']}>
                <div>
                  <Badge size="small" count={0}>
                    <Avatar size="small" src={user?.picture} />
                  </Badge>
                  <span className="name">{user?.given_name}</span>
                </div>
              </Dropdown>
            </div>
          </li>
        </ul>
      </div>
    </Header>
  );
};

export default AppHeader;
